<template>
  <div class="vl-card">
    <div class="head">
      <div>
        <span style="color:var(--color-primary)">{{ vl.plateNo }}</span>
        <span>一年一检</span>
      </div>
      <div>
        <span>年检有效期至</span>
        <span>{{vl.expireDate | date}}</span>
      </div>
    </div>
    <div class="body">
      <van-row>
        <van-col span="12">
          <div class="cell">
            <span>号牌</span><span>{{ vl.plateNo }}</span>
          </div>
        </van-col>
        <van-col span="12">
          <div class="cell">
            <span>车辆类型</span><span>{{ vl.vehicleType }}</span>
          </div>
        </van-col>
        <van-col span="24">
          <div class="cell">
            <span>所有人</span><span>{{ vl.owner }}</span>
          </div>
        </van-col>
        <van-col span="24">
          <div class="cell">
            <span>住址</span><span>{{ vl.address }}</span>
          </div>
        </van-col>
        <van-col span="24">
          <div class="cell">
            <span>品牌型号</span><span>{{ vl.model }}</span>
          </div>
        </van-col>
        <van-col span="24">
          <div class="cell">
            <span>车辆识别码</span><span>{{ vl.vin }}</span>
          </div>
        </van-col>
        <van-col span="12">
          <div class="cell">
            <span>使用性质</span><span>{{ vl.useCharacter }}</span>
          </div>
        </van-col>
        <van-col span="12">
          <div class="cell">
            <span>发动机号</span><span>{{ vl.engineNo }}</span>
          </div>
        </van-col>
        <van-col span="12">
          <div class="cell">
            <span>注册日期</span><span>{{ vl.registerDate | date }}</span>
          </div>
        </van-col>
        <van-col span="12">
          <div class="cell">
            <span>发证日期</span><span>{{ vl.issueDate | date }}</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="foot">
      <slot name="btns"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vl: { type: Object, default: () => { return {} }, required: false }
  }
}
</script>

<style scoped>
.vl-card{
  margin: 0px var(--mp) var(--mp) var(--mp);
  border: 1px solid var(--color-border);
  border-radius: 5px;
  min-height: 100px;
  background-color: white;
}

.head{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid var(--color-border);
}
.head > div{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.head span{
  font-size: .875rem;
  color: var(--color-text-sub);
}
.head > div:first-child > span:first-child{
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
}
.head > div:last-child {
  text-align: right;
}

.body{
  font-size: .875rem;
  padding-top: 5px;
}
.cell {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px 5px 5px;
}
.cell > span:first-child{
  color: var(--color-text-sub);
}
.cell > span:last-child{
}

</style>
