<template>
  <div class="page-ctx" style="padding-top:var(--mp);">
    <VLCard v-for="vl in vls" :key="vl.id" :vl="vl">
      <div slot="btns" class="btns-row" style="padding: 5px; border-top:1px solid var(--color-border);">
        <van-button :disabled="!enableBk(vl)" size="small" type="primary" @click="makeBk(vl)">预约</van-button>
        <van-button size="small" type="info" @click="$router.replace('/cust-vl/edit/'+vl.id)">编辑</van-button>
        <van-button size="small" type="danger" @click="rmVl(vl)">删除</van-button>
      </div>
    </VLCard>
    <div v-if="vls.length<vlMax" class="add-vl" @click="toOcr">
      <van-icon name="add-o" />
      <span>添加行驶证</span>
    </div>
    <div class="notice">
      <div class="notice-title">注意事项:</div>
      <ol>
        <li>非营运轿车（包括非营运轿车、非营运小型和微型载客汽车，但面包车、7座及7座以上车型除外）6年内免予上线检测，只需每两年申请检验标志；超过6年的，每年检验1次；超过15年的，每6个月检验1次。</li>
        <li>营运载客汽车5年以内每年检验1次；超过5年的，每6个月检验1次</li>
        <li>载货汽车和大型、中型非营运载客汽车10年以内每年检验1次，超过10年的每6个月检验1次</li>
        <li>摩托车4年以内每2年检验1次；超过4年的，每年检验1次</li>
        <li>系统根据年检规则无法判断出车辆年检类型的无法预约</li>
        <li>不在年检到期日之前90天以内的车辆无法预约</li>
      </ol>
    </div>
  </div>
</template>

<script>
import VLApi from '@/apis/VLApi'
import VLCard from '@/components/VLCard'
import { mapGetters, mapState } from 'vuex'
export default {
  components: { VLCard },
  computed: {
    ...mapGetters(['vlMax']),
    ...mapState({
      booking_enable_expired_vl: state => state.env.booking_enable_expired_vl
    })
  },
  data() {
    return {
      vls: []
    }
  },
  created() {
    this.getCustVLs()
  },
  methods: {
    enableBk(row) {
      return row.extData.enableBk
    },
    toOcr() {
      this.$router.replace('/ocr')
    },
    getCustVLs() {
      VLApi.getCustVLs().then(rsp => {
        this.vls = rsp.data
      })
    },
    makeBk(vl) {
      this.$router.replace({ path: '/cust-bk/makebooking', query: { id: vl.id, plateNo: vl.plateNo }})
    },
    rmVl(vl) {
      this.$dialog.confirm({ title: '删除车辆?', message: '车辆未完成的预约将被自动取消，请确认是否继续操作？' }).then(() => {
        VLApi.custDelVL(vl.id).then(() => {
          this.$toast.success('删除成功！')
          this.getCustVLs()
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.vl-list{
  padding-top: var(--mp);
}
.add-vl{
  margin: 0px var(--mp);
  border-radius: 5px;
  background-color: white;
  height: calc(100vw * 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-text-sub);
  border: 1px dashed var(--color-border);
}
.add-vl > i{
  font-size: 2rem;
  margin-bottom: .5rem;
}
</style>
